.App {
  /* text-align: center; */
  
  
}

body{
  background:linear-gradient(to right, rgba(255, 255, 254, 0.897), rgba(255, 255, 255, 0.849)), url("./Assets/first-image.jpeg");

}

.backgr-image{
  /* background-image: url("./Assets/first-image.jpeg");
  opacity: 0.1; */
 
}
.blogs{
  display: flex;
}

.blogpage{
  background: white;
  margin-top: 50px;
  padding: 5px 10px;
}

.link{
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.bloglink{
  text-decoration: none;
  color: rgb(2, 2, 2);
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}


ul{
  list-style: none;
}

.banner_container {
    /* background: linear-gradient(to right, rgb(173, 83, 137), rgb(60, 16, 83)); */
    width: 100%;
    color: rgb(255, 255, 255);
    padding: 80px;
    margin-top: 40px;
}

.background-image{
    background: linear-gradient(to bottom, rgba(173, 134, 83, 0.562), rgba(48, 31, 9, 0.747));
}
.banner_2,
.banner{
    margin-bottom: 100px;

}

.main_text{
    font-size: 70px;
    /* font-family: 'Cedarville Cursive', cursive; */

    font-weight: 800;
}

.banner_text_container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.intro_text{
    font-family: 'Space Grotesk', sans-serif;
    font-weight: light;
    line-height: 40px;
}

.first_text{
    font-size: 25px;
    font-family: 'Space Grotesk', sans-serif;
}

.banner_buton{
    background-color: white;
    padding: 10px;
    font-size: 18px;
    font-family: 'Space Grotesk', sans-serif;
    /* font-weight: ; */
    outline: none;
    border: none;
    border-radius: 50px;
    margin-top: 20px;
    transition: 0.3s ease-in-out;
    background: linear-gradient(to right, rgb(173, 134, 83), rgba(48, 31, 9, 0.747));
    color: white;

}

/* .banner_buton:hover{
    background: linear-gradient(to left, rgb(173, 83, 137), rgb(60, 16, 83));
    color: white;
 
} */

.image_box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* outline: white; */
    /* padding: 5px; */
   

    
}

.quote{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: black;
    background-color: rgb(255, 255, 255);
    margin: -20px 250px;
    padding: 30px;

    /* bottom: -10px; */
    border-radius: 160px;
    /* box-shadow: ; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-align: center;
    border-radius: 10px 10px black;
    transition: 0.3s ease;
    /* outline: black; */
}
/* .quote:hover{
    transform: scale(1.056);
    transform: rotate(5deg);
    filter:grayscale(25);
} */

.quote h3{
   /* background-color: white; */
   font-family: 'Space Grotesk', sans-serif;
   padding: 10px;
}
.imagess{
    height: 550px;
    border-top-right-radius: 150px;
    border-bottom-left-radius: 150px;
    border: 10px;
    /* padding: 5px;
    background-color: black; */
    margin-bottom: 20px;
    transform: rotate(10deg);

    /* position: absolute; */

}






/* banner2 */

.about_tick{
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.tick_circle{
    background-color: rgb(60, 16, 83);
    padding: 10px;
    color: white;
    border-radius: 50%;
    font-size: 20px;
    margin-right: 30px;
}
@media screen and (max-width:769px) {

    .first_text{
        /* transform: rotate(-5deg); */
        font-size: 20px;
    }
    .main_text{
        font-size: 50px;
        font-family: 'Space Grotesk', sans-serif;
        font-weight: 800;
    }

    .imagess{
        height: 450px;
        border-top-right-radius: 150px;
        border-bottom-left-radius: 150px;
        border: 10px;
        padding: 1px;
        background-color: black;
        margin-top: 30px;
    }

    .quote{
        margin-top: 30px;
    position: relative;
    margin: -20px 30px;
    padding: 25px;
    border-radius: 10px;

    }

    .quote h3{
    font-size: 20px;

    }
    .banner_container{
        padding: 70px 0;
    }
}